import React, { PropsWithChildren } from "react";
import { CompoundButton, IButtonStyles, useTheme } from "@fluentui/react";
import { navigate } from "gatsby";

const LOCAL_URL = /^\/(?!\/)/;

interface LinkButtonProps {
    title?: string;
    url?: string;
    extratext?: string;
    primary?: boolean;
    center?: boolean;
}

function onClick(this: HTMLElement) {
    const { url } = (this as any).data;
    LOCAL_URL.test(url) ? navigate(url) : window.open(url, `_blank`);
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = (props: PropsWithChildren<LinkButtonProps>) => {
    const { children, title, url, extratext = undefined, primary, center } = props;
    const theme = useTheme();

    const buttonStyle: IButtonStyles = {
        root: {
            backgroundColor: primary ? theme.palette.green : theme.palette.white,
            width: `100%`,
            minHeight: `80px`,
        },
        rootHovered: {
            backgroundColor: primary ? theme.palette.greenLight : theme.palette.neutralLighter,
        },
        label: {
            color: theme.palette.black,
            textAlign: center ? "center" : undefined,
        },
        description: {
            color: theme.palette.neutralDark,
        },
    };

    const buttonContent = Array.isArray(children) ? children[0] : children;

    return (
        <CompoundButton
            styles={buttonStyle}
            secondaryText={extratext}
            onClick={onClick}
            title={title}
            data={{ url: url }}
        >
            {buttonContent}
        </CompoundButton>
    );
};

export default LinkButton;
