import React, { PropsWithChildren } from "react";
import rehypeReact from "rehype-react";

import LinkRegular from "./LinkRegular";
import LinkButton from "./LinkButton";

const renderAst = new (rehypeReact as any)({
    createElement: React.createElement,
    components: {
        a: LinkRegular,
        linkbutton: LinkButton,
    },
}).Compiler;

interface MarkdownGraphQL {
    htmlAst: string;
}

const Markdown: React.FunctionComponent<MarkdownGraphQL> = (props: PropsWithChildren<MarkdownGraphQL>) => {
    return renderAst(props.htmlAst);
};

export default Markdown;
